import { lazy } from "react";

const ForgotPasswordPage = lazy(() => import("./pages/auth/forgotPassword"));
const SignUpPage = lazy(() => import("./pages/auth/signUp"));
const LoginPage = lazy(() => import("./pages/auth/login"));
const ResetPassword = lazy(() => import("./pages/auth/resetPassword"));

const DashBoard = lazy(() => import("./pages/dashboard/Interview"));
const Integration = lazy(() => import("./pages/dashboard/Integration"));
const People = lazy(() => import("./pages/People"));
const Administration = lazy(() => import("./pages/dashboard/Administration"));
const Settings = lazy(() => import("./pages/dashboard/Settings"));
const Profile = lazy(() => import("./pages/UserProfile/Profile"));
const InterviewDetailPage = lazy(
  () => import("./pages/dashboard/InterviewDetail"),
);
const CandidateDetailPage = lazy(
  () => import("./pages/dashboard/CandidateDetail"),
);
const PeopleDetails = lazy(() => import("./pages/People/PeopleDetails"));
const GroupDetails = lazy(() => import("./pages/Groups/GroupsDetails"));

const Interview = lazy(() => import("./pages/interview"));

const ParticipantInterview = lazy(() => import("./pages/public/interview"));

const EditInterviewPage = lazy(() => import("./pages/dashboard/EditInterview"));

const CreateCompanyPage = lazy(() => import("./pages/CreateCompany"));
const PreviewInterviewPage = lazy(() => import("./pages/PreviewInterview"));

export const publicRoutes = [
  {
    path: "participant/interview/:interviewToken",
    name: "Participant Interview",
    component: ParticipantInterview,
    layout: "Public",
  },
];

export const authRoutes = [
  {
    path: "/signup",
    name: "signup",
    component: SignUpPage,
    layout: "auth",
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    layout: "auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordPage,
    layout: "auth",
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    layout: "auth",
  },
];

export const privateRoutes = [
  {
    path: "/interview",
    name: "DashBoard",
    component: DashBoard,
    layout: "dashboard",
  },
  {
    path: "/integration",
    name: "Integration",
    component: Integration,
    layout: "dashboard",
  },
  {
    path: "/people",
    name: "People",
    component: People,
    layout: "dashboard",
  },
  {
    path: "/administration",
    name: "Admin",
    component: Administration,
    layout: "dashboard",
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "dashboard",
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    layout: "dashboard",
  },
  {
    path: "interview/:interviewId",
    name: "Interview Details",
    component: InterviewDetailPage,
    layout: "dashboard",
  },
  {
    path: "interview/:interviewId/participant/:candidateId",
    name: "Candidate Details",
    component: CandidateDetailPage,
    layout: "dashboard",
  },
  {
    path: "/people/:personId",
    name: "People",
    component: PeopleDetails,
    layout: "dashboard",
  },
  {
    path: "/group/:groupId",
    name: "People",
    component: GroupDetails,
    layout: "dashboard",
  },
  {
    path: "/interview/:interviewId/edit",
    name: "Edit Interview",
    component: EditInterviewPage,
    layout: "dashboard",
  },
  {
    path: "create-company",
    name: "Create Company",
    component: CreateCompanyPage,
    layout: "dashboard",
  },
  {
    path: "interview/preview/:interviewId",
    name: "Preview Interview",
    component: PreviewInterviewPage,
    layout: "dashboard",
  },
];

export const interViewRoutes = [
  {
    path: "interview/create",
    name: "Interview",
    component: Interview,
    layout: "interview",
  },
  {
    path: "interview/:id/:type",
    name: "Interview",
    component: Interview,
    layout: "interview",
  },
];
