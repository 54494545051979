import { IDashboard } from "src/modules/dashboard/Interview/interface/dashboard.type";
import { IListParams } from "src/utils/common.type";
import { Api } from "./Api";
export class DashboardService extends Api<IDashboard | string | boolean> {
  constructor() {
    super("");
  }
  async getInterviews(payload?: IListParams) {
    const params = new URLSearchParams();
    if (payload?.search) {
      params.append("search", payload.search);
    } else {
      params.append("page", `${payload?.page || 1}`);
    }
    return this.getAll("interviews", params.toString());
  }

  async scheduleInterview(
    data: { scheduled_at?: string; is_immediate?: boolean },
    id: number,
  ) {
    return this.post(data, `interviews/${id}/schedule`);
  }

  async getDashboardData() {
    return this.getAll(`dashboard`);
  }
}
