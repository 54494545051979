import cn from "classnames";
import { Link, LinkProps } from "react-router-dom";
import classes from "./link.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const NavLink: React.FC<Props & LinkProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <Link
      {...rest}
      className={cn(classes.link, {
        [className]: !!className,
      })}
    >
      {children}
    </Link>
  );
};

export default NavLink;
