import { Navigate, Route, Routes } from "react-router";

import { ToastContainer } from "react-toastify";
import classes from "./App.module.scss";
import AuthLayout from "./layout/AuthLayout";
import InterviewLayout from "./layout/InterviewLayout";
import PrivateLayout from "./layout/PrivateLayout";
import PublicLayout from "./layout/PublicLayout";
import {
  authRoutes,
  interViewRoutes,
  privateRoutes,
  publicRoutes,
} from "./routes";
// eslint-disable-next-line no-restricted-imports
import "react-day-picker/dist/style.css";

const App = () => {
  return (
    <>
      <div className={classes.app}>
        <Routes>
          {authRoutes.map(({ path, component: Component }, key) => {
            return (
              <Route
                path={"/auth" + path}
                element={
                  <AuthLayout>
                    <Component />
                  </AuthLayout>
                }
                key={key}
              />
            );
          })}
          {privateRoutes.map(({ path, component: Component }, key) => {
            return (
              <Route
                path={"/" + path}
                element={
                  <PrivateLayout>
                    <Component />
                  </PrivateLayout>
                }
                key={key}
              />
            );
          })}
          {interViewRoutes.map(({ path, component: Component }, key) => {
            return (
              <Route
                path={"/" + path}
                element={
                  <InterviewLayout>
                    <Component />
                  </InterviewLayout>
                }
                key={key}
              />
            );
          })}
          {publicRoutes.map(({ path, component: Component }, key) => {
            return (
              <Route
                path={"/" + path}
                element={
                  <PublicLayout>
                    <Component />
                  </PublicLayout>
                }
                key={key}
              />
            );
          })}

          <Route path="*" element={<Navigate to="/interview" replace />} />
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
};

export default App;
