import {
  // AdminIcon,
  // IntegrateIcon,
  InterViewIcon,
  InterviewFillIcon,
  PeopleGroupFillIcon,
  PeopleIcon,
} from "src/assets/icons";

export const sidebarMenu = [
  {
    title: "Interviews",
    route: "/interview",
    icon: <InterViewIcon />,
    tabSelectedIcon: <InterviewFillIcon />,
  },
  // {
  //   title: "Integrations",
  //   route: "/integration",
  //   icon: <IntegrateIcon />,
  // },
  {
    title: "People",
    route: "/people",
    matchingRoute: "/group",
    icon: <PeopleIcon />,
    tabSelectedIcon: <PeopleGroupFillIcon />,
  },
  // {
  //   title: "Administration",
  //   route: "/administration",
  //   icon: <AdminIcon />,
  // },
  // {
  //   title: "Settings",
  //   route: "/settings",
  //   icon: <SettingsIcon />,
  // },
];

export const STATUS = {
  ACTIVE: "active",
  OPENED: "opened",
  SENT: "sent",
};

export const INTERVIEW_STATUS = {
  NOT_STARTED: "notStarted",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
};
