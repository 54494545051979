import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { IAuthResponse } from "src/modules/auth/interface/auth.type";
import {
  forgotPassword,
  loginUser,
  registerUser,
  resetPassword,
  ssoAuth,
} from "../actions";
export interface IAuthSlice {
  user: IAuthResponse | null;
  message: string;
  loading: boolean;
  error: string;
}

const initialState: IAuthSlice = {
  user: null,
  loading: false,
  error: "",
  message: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      Cookies.remove("token");
      window.location.reload();
      return initialState;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //register-user
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });

    //login-user
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });
    //forgot-password
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });
    //reset-password
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.data.status;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });
    //sso-authentication
    builder.addCase(ssoAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ssoAuth.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase(ssoAuth.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const { logout, reset } = authSlice.actions;
export default authSlice.reducer;
