import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { AuthService } from "src/api/AuthService";
import {
  IAuth,
  IAuthResponse,
  IForgotPassword,
  IResetPassword,
  IResetPasswordResponse,
} from "src/modules/auth/interface/auth.type";
import { IResponse } from "src/utils/common.type";

const authService = new AuthService();
interface IPayload<T, k = T> {
  //by default callback type will be T if not specified
  data: T;
  cb?: (response: IResponse<k>) => void;
}

export const registerUser = createAsyncThunk(
  "auth/register",
  async (payload: IPayload<IAuth, IAuthResponse>) => {
    const { data, cb } = payload;
    try {
      const resp = await authService.register(data as IAuth);
      const token = resp?.data?.data?.token;
      if (token) {
        Cookies.set("token", token);
      }
      if (cb) {
        cb(resp);
      }
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IAuthResponse>;

      throw error;
    }
  },
);
export const loginUser = createAsyncThunk(
  "auth/login",
  async (payload: IPayload<IAuth, IAuthResponse>) => {
    const { data, cb } = payload;
    try {
      const resp = await authService.login(data as IAuth);
      const token = resp?.data?.data?.token;
      if (token) {
        Cookies.set("token", token);
      }
      if (cb) {
        cb(resp);
      }
      return resp?.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IAuthResponse>;
      throw error;
    }
  },
);
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (payload: IPayload<IForgotPassword, IResetPasswordResponse>) => {
    const { data, cb } = payload;
    try {
      const resp = await authService.forgotPassword(data as IForgotPassword);
      if (resp?.data?.status) {
        if (cb) {
          cb(resp as IResponse<IResetPasswordResponse>);
        }
        return { message: resp?.data?.status, status: true };
      } else {
        throw { ...resp, status: false };
      }
    } catch (err) {
      const { error } = err as IResponse<IForgotPassword>;
      if (cb) {
        cb(err as IResponse<IResetPasswordResponse>);
      }

      throw error;
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload: IPayload<IResetPassword, IResetPasswordResponse>) => {
    const { data, cb } = payload;

    try {
      const resp = await authService.resetPassword(data as IResetPassword);

      if (resp?.data?.status) {
        if (cb) {
          cb(resp as IResponse<IResetPassword>);
        }
        return { ...resp, status: true };
      } else {
        throw { ...resp, status: false };
      }
    } catch (err) {
      const { error }: { error: { email: string } } = err as {
        error: { email: string };
      }; //Custom type as getting response from BE
      throw { message: error?.email || "Something went wrong" };
    }
  },
);

export const ssoAuth = createAsyncThunk(
  "auth/ssoAuth",
  async (payload: IPayload<{ code: string }, IAuthResponse>) => {
    const { data, cb } = payload;
    try {
      const resp = await authService.ssoAuth(data as { code: string });
      const token = resp?.data?.data.token;
      if (token) {
        Cookies.set("token", token);
      }
      if (cb) {
        cb(resp);
      }
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IAuthResponse>;
      if (cb) {
        cb(err as IResponse<IAuthResponse>);
      }
      throw error;
    }
  },
);
