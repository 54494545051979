import {
  IAuth,
  IForgotPassword,
  IResetPassword,
} from "src/modules/auth/interface/auth.type";
import { Api } from "./Api";

export class AuthService extends Api<IAuth> {
  constructor() {
    super("");
  }

  register(payload: IAuth) {
    return this.post(payload, "register");
  }

  login(payload: IAuth) {
    return this.post(payload, "login");
  }

  forgotPassword(payload: IForgotPassword) {
    return this.post(payload, "forgot-password");
  }

  resetPassword(payload: IResetPassword) {
    return this.post(payload, "reset-password");
  }
  ssoAuth(payload: { code: string }) {
    return this.post(payload, "oauth/google");
  }
}
