import React from "react";
import { useLocation } from "react-router";
import { EvaluaitLogo } from "src/assets/icons";
import { useAppSelector } from "src/store/hooks";
import classes from "./authHeader.module.scss";

const AuthHeader = () => {
  const { pathname } = useLocation();
  const { message: isResetPasswordSuccess } = useAppSelector(
    (state) => state.auth,
  );

  const getAuthHeader = () => {
    switch (pathname) {
      case "/auth/login":
        return (
          <>
            <div className={classes.title}>Login</div>
          </>
        );
      case "/auth/forgot-password":
        return (
          <>
            <div className={classes.title}>Forgot Password</div>
            <div className={classes.info}>
              Please provide the email address you used when you signed up for
              your account
            </div>
          </>
        );
      case "/auth/reset-password":
        return (
          <>
            {!isResetPasswordSuccess ? (
              <>
                <div className={classes.title}>Password Reset</div>
              </>
            ) : (
              <>
                <div className={classes.title}>Password Changes</div>
                <div className={classes.info}>
                  Your password has been changed successfully
                </div>
              </>
            )}
          </>
        );
      default:
        return (
          <>
            <div className={classes.title}>Sign Up</div>
          </>
        );
    }
  };
  return (
    <div className={classes.headerContainer}>
      <div className={classes.logoWrapper}>
        <EvaluaitLogo />
      </div>
      {getAuthHeader()}
    </div>
  );
};

export default AuthHeader;
