import { createSlice } from "@reduxjs/toolkit";
import {
  IInterview,
  IPublicParticipant,
} from "src/modules/interview/interface/interview.type";
import {
  createInterview,
  getInterview,
  getInterviewCandidates,
  getInterviewTopParticipants,
  getPublicInterviewDetails,
  optimizeInterview,
  postInterViewAnswer,
  postParticipantInterViewAnswer,
  startInterView,
  startParticipantInterView,
  updateInterview,
} from "../actions";

export interface IInterviewSlice {
  interview: IInterview | null;
  message: string;
  loading: boolean;
  getDataLoading: boolean;
  error: string;
  threadId: string;
  interviewQuestion: string;
  goal_prompt?: string;
  seed_prompt?: string;
  promptLoading?: boolean;
  publicParticipant?: IPublicParticipant | null;
  publicInterviewLoading?: boolean;
  candidateLoading?: boolean;
  topCandidateLoading?: boolean;
  startInterviewLoading?: boolean;
}

const initialState: IInterviewSlice = {
  interview: null,
  loading: false,
  error: "",
  message: "",
  getDataLoading: false,
  threadId: "",
  interviewQuestion: "",
  goal_prompt: "",
  seed_prompt: "",
  promptLoading: false,
  publicParticipant: null,
  publicInterviewLoading: false,
  candidateLoading: false,
  topCandidateLoading: false,
  startInterviewLoading: false,
};

export const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    reset: () => initialState,

    setInterview: (state, action) => {
      state.interview = action.payload;
    },
  },
  extraReducers: (builder) => {
    //create-interview
    builder.addCase(createInterview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createInterview.fulfilled, (state, action) => {
      state.loading = false;
      state.interview = {
        ...action.payload.data,
        original_goal: action.payload.original_goal,
      };
    });
    builder.addCase(createInterview.rejected, (state, action) => {
      state.loading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //interview-participants
    builder.addCase(getInterviewCandidates.pending, (state) => {
      state.candidateLoading = true;
    });
    builder.addCase(getInterviewCandidates.fulfilled, (state, action) => {
      state.candidateLoading = false;
      state.interview = {
        ...state.interview,
        participants: action.payload,
      };
    });
    builder.addCase(getInterviewCandidates.rejected, (state, action) => {
      state.candidateLoading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //interview-top-participants
    builder.addCase(getInterviewTopParticipants.pending, (state) => {
      state.topCandidateLoading = true;
    });
    builder.addCase(getInterviewTopParticipants.fulfilled, (state, action) => {
      state.topCandidateLoading = false;
      state.interview = {
        ...state.interview,
        top_participants: action.payload,
      };
    });
    builder.addCase(getInterviewTopParticipants.rejected, (state, action) => {
      state.topCandidateLoading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //get-interview
    builder.addCase(getInterview.pending, (state) => {
      state.getDataLoading = true;
      state.interview = null;
    });
    builder.addCase(getInterview.fulfilled, (state, action) => {
      state.getDataLoading = false;
      state.interview = { ...(state.interview || {}), ...action.payload };
    });
    builder.addCase(getInterview.rejected, (state, action) => {
      state.getDataLoading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //update-interview
    builder.addCase(updateInterview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInterview.fulfilled, (state, action) => {
      state.loading = false;
      state.interview = action.payload;
    });
    builder.addCase(updateInterview.rejected, (state, action) => {
      state.loading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //start-interview
    builder.addCase(startInterView.pending, (state) => {
      state.startInterviewLoading = true;
    });
    builder.addCase(startInterView.fulfilled, (state, action) => {
      state.startInterviewLoading = false;
      state.threadId = action.payload.threadId;
      state.interviewQuestion = action.payload.question;
    });
    builder.addCase(startInterView.rejected, (state, action) => {
      state.startInterviewLoading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //post-interview-ans
    builder.addCase(postInterViewAnswer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postInterViewAnswer.fulfilled, (state, action) => {
      state.loading = false;
      state.interviewQuestion = action.payload.question;
    });
    builder.addCase(postInterViewAnswer.rejected, (state, action) => {
      state.loading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //optimize-interview
    builder.addCase(optimizeInterview.pending, (state) => {
      state.promptLoading = true;
    });
    builder.addCase(optimizeInterview.fulfilled, (state, action) => {
      state.promptLoading = false;
      state[`${action.payload.type as "goal" | "seed"}_prompt`] =
        action?.payload.data;
    });
    builder.addCase(optimizeInterview.rejected, (state, action) => {
      state.promptLoading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //public-interview-details
    builder.addCase(getPublicInterviewDetails.pending, (state) => {
      state.publicInterviewLoading = true;
    });
    builder.addCase(getPublicInterviewDetails.fulfilled, (state, action) => {
      state.publicInterviewLoading = false;
      state.publicParticipant = action.payload;
    });
    builder.addCase(getPublicInterviewDetails.rejected, (state, action) => {
      state.publicInterviewLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    //start-participant-interview
    builder.addCase(startParticipantInterView.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(startParticipantInterView.fulfilled, (state, action) => {
      state.loading = false;
      state.threadId = action.payload.threadId;
      state.interviewQuestion = action.payload.question;
    });
    builder.addCase(startParticipantInterView.rejected, (state, action) => {
      state.loading = false;
      state.interview = null;
      state.error = action.error.message || "Something went wrong";
    });

    //post-participant-interview-ans
    builder.addCase(postParticipantInterViewAnswer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      postParticipantInterViewAnswer.fulfilled,
      (state, action) => {
        state.loading = false;
        state.interviewQuestion = action.payload.question;
      },
    );
    builder.addCase(
      postParticipantInterViewAnswer.rejected,
      (state, action) => {
        state.loading = false;
        state.interview = null;
        state.error = action.error.message || "Something went wrong";
      },
    );
  },
});

export const { reset, setInterview } = interviewSlice.actions;

export default interviewSlice.reducer;
