import cn from "classnames";
import classes from "./buttonLoader.module.scss";

interface Props {
  className?: string;
}
const ButtonLoader: React.FC<Props> = ({ className = "" }) => {
  return (
    <div className={cn(classes.loader, { [className]: !!className })}></div>
  );
};

export default ButtonLoader;
