import { createSlice } from "@reduxjs/toolkit";
import { saveAudio } from "../actions";

export interface IAudioSlice {
  audio: Blob | null;
  message: string;
  loading: boolean;
  error: string;
  sideBar?: boolean;
}

const initialState: IAudioSlice = {
  audio: null,
  loading: false,
  error: "",
  message: "",
  sideBar: false,
};

export const MediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    toggleSideBar: (state, action?: { payload?: boolean; type: string }) => {
      state.sideBar = action?.payload ?? !state.sideBar;
    },
  },
  extraReducers: (builder) => {
    //create-interview
    builder.addCase(saveAudio.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveAudio.fulfilled, (state, action) => {
      state.loading = false;
      state.audio = action.payload.data;
    });
    builder.addCase(saveAudio.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const { toggleSideBar } = MediaSlice.actions;
export default MediaSlice.reducer;
