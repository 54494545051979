import { createSlice } from "@reduxjs/toolkit";
import {
  IDashboard,
  IDashboardData,
} from "src/modules/dashboard/Interview/interface/dashboard.type";
import { IPagination } from "src/utils/common.type";

import { initialMeta } from "src/utils/helper";
import {
  getDashboardData,
  getInterviews,
  scheduleInterviews,
} from "../actions";

export interface IDashboardSlice {
  dashboard: IDashboard[];
  message: string;
  loading: boolean;
  interviewListLoading: boolean;
  error: string;
  meta: IPagination | null;
  dashBoarData?: IDashboardData | null;
}

const initialState: IDashboardSlice = {
  dashboard: [],
  loading: false,
  error: "",
  message: "",
  meta: initialMeta,
  interviewListLoading: false,
  dashBoarData: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboard: (state, action) => {
      state.dashboard = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get-interviews
    builder.addCase(getInterviews.pending, (state) => {
      state.interviewListLoading = true;
    });
    builder.addCase(getInterviews.fulfilled, (state, action) => {
      state.interviewListLoading = false;
      state.dashboard = action.payload.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getInterviews.rejected, (state, action) => {
      state.interviewListLoading = false;
      state.dashboard = [];
      state.error = action.error.message || "Something went wrong";
    });

    //schedule-interviews
    builder.addCase(scheduleInterviews.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(scheduleInterviews.fulfilled, (state, action) => {
      const Interviews = state.dashboard || [];
      const index = [...state.dashboard].findIndex(
        (item) => item.id === action.payload.id,
      );
      if (index !== -1) {
        Interviews[index] = {
          ...Interviews[index],
          scheduled_at: action.payload.scheduled_at,
          is_immediate: action.payload.is_immediate,
        };
      }
      state.loading = false;
      state.dashboard = [...Interviews];
    });
    builder.addCase(scheduleInterviews.rejected, (state, action) => {
      state.loading = false;
      state.dashboard = [];
      state.error = action.error.message || "Something went wrong";
    });

    //get-dashboardData
    builder.addCase(getDashboardData.pending, (state) => {
      state.interviewListLoading = true;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.interviewListLoading = false;
      state.dashBoarData = action.payload;
    });
    builder.addCase(getDashboardData.rejected, (state, action) => {
      state.interviewListLoading = false;
      state.dashBoarData = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
