import Cookies from "js-cookie";
import { IPagination } from "./common.type";
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordValidator = {
  length: /^.{8,}$/,
  uppercase: /[A-Z]/,
  lowercase: /[a-z]/,
  number: /[0-9]/,
  unique: /[^\w]/,
  alphabet: /.*[a-zA-Z].*/,
};
export const isLoggedIn = () => {
  const token = Cookies.get("token");
  return !!token;
};

export const initialMeta: IPagination = {
  current_page: 0,
  from: 1,
  last_page: 1,
  links: [],
  path: "",
  per_page: 15,
  to: 15,
  total: 15,
};
