import { createSlice } from "@reduxjs/toolkit";
import { ISettings, IUser } from "src/modules/users/interface/user.type";
import {
  changePassword,
  createCompany,
  deleteUser,
  getSettings,
  getUser,
  updateUser,
  upgradeNow,
} from "../actions";

export interface IUserSlice {
  user: IUser | null;
  loading: boolean;
  upgradeLoading: boolean;
  error: string;
  changePasswordLoading: boolean;
  setting?: ISettings | null;
  settingsLoading?: boolean;
  changePasswordError: string;
  deleteUserLoading?: boolean;
  deleteUserError?: string;
  updateUserLoading?: boolean;
  updateUserError?: string;
}

const initialState: IUserSlice = {
  user: null,
  loading: false,
  upgradeLoading: false,
  error: "",
  changePasswordLoading: false,
  setting: null,
  changePasswordError: "",
  deleteUserLoading: false,
  deleteUserError: "",
  updateUserLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setInterviewCount: (state) => {
      state.user = {
        ...state.user,
        interviews_count: (state?.user?.interviews_count || 0) + 1,
      };
    },
  },
  extraReducers: (builder) => {
    //get-user
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });
    //create-company
    builder.addCase(createCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.user = { ...state.user, company: action.payload };
    });
    builder.addCase(createCompany.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });
    //upgrade-plan
    builder.addCase(upgradeNow.pending, (state) => {
      state.upgradeLoading = true;
    });
    builder.addCase(upgradeNow.fulfilled, (state) => {
      state.upgradeLoading = false;
    });
    builder.addCase(upgradeNow.rejected, (state, action) => {
      state.upgradeLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    //change-password
    builder.addCase(changePassword.pending, (state) => {
      state.changePasswordLoading = true;
      state.changePasswordError = "";
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.changePasswordLoading = false;
      state.changePasswordError = "";
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePasswordLoading = false;
      state.changePasswordError =
        action.error.message || "Something went wrong";
    });

    //update-user
    builder.addCase(updateUser.pending, (state) => {
      state.updateUserLoading = true;
      state.updateUserError = "";
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.updateUserLoading = false;
      state.updateUserError = "";
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateUserLoading = false;
      state.updateUserError = action.error.message || "Something went wrong";
    });

    //get-settings
    builder.addCase(getSettings.pending, (state) => {
      state.settingsLoading = true;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.settingsLoading = false;
      state.setting = action.payload;
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message || "Something went wrong";
    });

    //delete-user
    builder.addCase(deleteUser.pending, (state) => {
      state.deleteUserLoading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.deleteUserLoading = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.deleteUserLoading = false;
      state.deleteUserError = action.error.message || "Something went wrong";
    });
  },
});
export const { setInterviewCount } = userSlice.actions;
export default userSlice.reducer;
