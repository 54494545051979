import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "src/layout/AuthLayout/AuthHeader";
import { isLoggedIn } from "src/utils/helper";
import classes from "./authLayout.module.scss";

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  const isAuthenticated = isLoggedIn();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/interview");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <AuthHeader />
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
