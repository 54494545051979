import { notify } from "src/components/Toastify/Toastify";
import { upgradeNow } from "src/store/actions";
import { useAppSelector, useStoreActions } from "src/store/hooks";

export const useUpgradeUser = () => {
  const actions = useStoreActions({ upgradeNow });
  const { loading, upgradeLoading, error } = useAppSelector(
    (state) => state.user,
  );

  const handleUpgrade = async (cb?: () => void) => {
    actions.upgradeNow({
      data: "",
      cb: () => {
        if (cb) {
          cb();
          notify({
            message:
              "Thank you for your interest, you've been added to our upgrade list.",
          });
        }
      },
    });
  };

  return {
    handleUpgrade,
    loading,
    upgradeLoading,
    error,
  };
};
