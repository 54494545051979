import {
  IChangePassword,
  ICompany,
  IUser,
} from "src/modules/users/interface/user.type";
import { Api } from "./Api";

export class UserService extends Api<IUser | IChangePassword | FormData> {
  constructor() {
    super("");
  }

  async getUser() {
    return this.get("me");
  }
  async createCompany(name: Pick<ICompany, "name">) {
    return this.post(name, "create-company");
  }

  async upgradeNow() {
    return this.post({}, "upgrade");
  }

  async changePassword(payload: IChangePassword) {
    return this.post(payload, "change-password");
  }

  async updateUser(payload: FormData) {
    return this.post(payload, "profile");
  }

  async getSettings() {
    return this.get("bootstrap");
  }

  //not need id for this so first parameter is undefined
  async deleteUser(payload: { email: string }) {
    return this.delete("profile", payload);
  }
}
