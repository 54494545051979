import React, { useEffect, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";

import Loader from "src/components/Loader";
import { getSettings, getUser } from "src/store/actions";
import { useAppSelector, useStoreActions } from "src/store/hooks";
import { isLoggedIn } from "src/utils/helper";
import classes from "./interviewLayout.module.scss";
interface Props {
  children: React.ReactNode;
}

const InterviewLayout: React.FC<Props> = ({ children }) => {
  const isAuthenticated = isLoggedIn();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isCreateInterview = pathname === "/interview/create";
  const { setting, user, loading, settingsLoading } = useAppSelector(
    (state) => state.user,
  );
  const { loading: createInterviewLoading } = useAppSelector(
    (state) => state.interview,
  );
  const actions = useStoreActions({ getUser, getSettings });

  useEffect(() => {
    if (!user) {
      actions.getUser();
    }
    if (!setting) {
      actions.getSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setting]);

  useEffect(() => {
    if (!loading && !settingsLoading) {
      if (!isAuthenticated && !user) {
        navigate("/auth/login");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, user, loading, settingsLoading]);

  const shouldRedirectToCreateCompany = useMemo(() => {
    const isCreateCompanyRoute = pathname.includes("create-company");

    return !isCreateCompanyRoute && !loading && !!user && !user.company;
  }, [user, pathname, loading]);

  const shouldRedirectToInterview = useMemo(
    () =>
      !!setting &&
      !!user &&
      !!user.company &&
      (setting.max_interviews || 1) <= (user?.interviews_count || 0) &&
      isCreateInterview &&
      !createInterviewLoading,
    [setting, user],
  );

  if (!isAuthenticated) {
    return null;
  }

  if (shouldRedirectToCreateCompany) {
    return <Navigate to="/create-company" />;
  }

  if (shouldRedirectToInterview) {
    return <Navigate to="/interview" />;
  }

  return (
    <>
      {!user?.id || loading || settingsLoading ? (
        <Loader showFullPage />
      ) : (
        <div className={classes.interviewContainer}>
          <div className={classes.interviewWrapper}>{children}</div>
        </div>
      )}
    </>
  );
};

export default InterviewLayout;
