import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AsyncThunkConfig,
  GetThunkAPI,
  // eslint-disable-next-line import/no-unresolved
} from "@reduxjs/toolkit/dist/createAsyncThunk";
import { DashboardService } from "src/api/DashboardService";
import {
  IDashboard,
  IDashboardData,
} from "src/modules/dashboard/Interview/interface/dashboard.type";
import { IListParams, IPagination, IResponse } from "src/utils/common.type";
import { RootState } from "..";
import { setInterview } from "../reducers/interview";

const dashboardService = new DashboardService();
interface IPayload<T> {
  data: T;
  cb?: (response: IResponse<T>) => void;
}
export const getInterviews = createAsyncThunk(
  "dashboard/getInterviews",
  async (payload?: IListParams) => {
    try {
      const resp = await getInterviewApi(payload);

      return resp?.data as { data: IDashboard[]; meta: IPagination };
    } catch (err) {
      const { error } = err as IResponse<IDashboard>;

      throw error;
    }
  },
);

export const scheduleInterviews = createAsyncThunk(
  "dashboard/scheduleInterviews",
  async (
    payload: IPayload<{
      scheduled_at: string;
      id: number;
      is_immediate: boolean;
      isSubmitting?: boolean;
    }>,
    { getState, dispatch }: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    const { data, cb } = payload;
    try {
      const { interview } = (getState() as RootState).interview;
      if (data.isSubmitting) {
        const schedule = data.is_immediate
          ? { is_immediate: true }
          : { scheduled_at: data.scheduled_at };
        await dashboardService.scheduleInterview(schedule, data.id);
      }
      if (cb) {
        cb({});
      }
      const updatedInterview = {
        ...interview,
        scheduled_at: data.scheduled_at,
      };
      dispatch(setInterview(updatedInterview));
      return data;
    } catch (err) {
      const { error } = err as IResponse<IDashboard>;

      throw error;
    }
  },
);

// only to search interview. for dropdown selection
// do not need to store in redux
export const getInterviewApi = async (payload?: IListParams) => {
  return dashboardService.getInterviews(payload);
};

export const getDashboardData = createAsyncThunk(
  "dashboard/getDashboardData",
  async () => {
    try {
      const resp = await dashboardService.getDashboardData();

      return resp?.data;
    } catch (err) {
      const { error } = err as IResponse<IDashboardData>;

      throw error;
    }
  },
);
