import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import groupReducer from "./group";
import interviewReducer from "./interview";
import externalReducer from "./media";
import personReducer from "./person";
import userReducer from "./user";
export const reducers = {
  auth: authReducer,
  interview: interviewReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  media: externalReducer,
  person: personReducer,
  group: groupReducer,
};
