import {
  IGroup,
  IMutateGroup,
} from "src/modules/dashboard/People/interface/group.type";
import {
  IMutatePerson,
  IPerson,
} from "src/modules/dashboard/People/interface/person.type";
import { IAnswer } from "src/modules/interview/interface/interview.type";
import { IListParams } from "src/utils/common.type";
import { Api } from "./Api";

export class PersonService extends Api<
  IPerson | IGroup | FormData | IMutatePerson
> {
  constructor() {
    super("");
  }
  // People
  async getPersonsList(payload?: IListParams) {
    const params = new URLSearchParams();
    if (payload?.search) {
      params.append("search", payload.search);
    } else {
      params.append("page", `${payload?.page || 1}`);
    }
    return this.getAll("participants", params.toString());
  }

  async getPerson(id: number) {
    return this.get(`participants/${id}`);
  }
  async addPerson(payload: IMutatePerson) {
    return this.post(payload, "participants");
  }

  async editPerson(id: number, payload: IMutatePerson) {
    return this.put(`participants/${id}`, payload);
  }

  async deletePerson(id: number) {
    return this.delete(`participants/${id}`);
  }
  //public-interview
  async getInterviewDetails(interviewToken: string) {
    return this.get(`participant/${interviewToken}`);
  }
  async startPublicInterview(id: string) {
    return this.post({}, `participant/${id}/start`);
  }
  async postPublicInterViewAnswer(payload: IAnswer) {
    const { interviewToken, answer } = payload;
    if (!answer) {
      return;
    }
    return this.post(answer, `participant/${interviewToken}/answer`);
  }

  async getInterviewParticipant(interviewId: number, candidateId: number) {
    return this.get(`interviews/${interviewId}/participants/${candidateId}`);
  }

  // Group
  async getGroupList(payload?: IListParams) {
    const params = new URLSearchParams();
    if (payload) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] !== undefined) {
          params.append(key, `${payload[key]}`);
        }
      });
    }

    // if (payload?.search) {
    //   params.append("search", payload.search);
    // } else {
    //   params.append("page", `${payload?.page || 1}`);
    // }

    return this.getAll("groups", params.toString());
  }
  async getGroup(id: number) {
    return this.get(`groups/${id}`);
  }
  async addGroup(payload: Partial<IMutateGroup>) {
    return this.post(payload as IGroup, "groups");
  }

  async editGroup(id: number, payload: IMutateGroup) {
    return this.put(`groups/${id}`, payload);
  }

  async deleteGroup(id: number) {
    return this.delete(`groups/${id}`);
  }

  async getGroupCandidates(id: number) {
    return this.get(`groups/${id}/participants`);
  }

  async deleteGroupCandidate({
    groupId,
    participantId,
  }: {
    groupId: number;
    participantId: number;
  }) {
    return this.delete(`groups/${groupId}/participants/${participantId}`);
  }

  async attachGroupInterview(
    interviewId: number,
    payload: Pick<IMutatePerson, "groups">,
  ) {
    return this.post(payload, `interviews/${interviewId}/groups`);
  }
}
