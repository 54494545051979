import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AsyncThunkConfig,
  GetThunkAPI,
  // eslint-disable-next-line import/no-unresolved
} from "@reduxjs/toolkit/dist/createAsyncThunk";
import { PersonService } from "src/api/PersonService";
import { notify } from "src/components/Toastify/Toastify";
import {
  IGroup,
  IGroupCandidates,
  IMutateGroup,
  IResponseGroup,
} from "src/modules/dashboard/People/interface/group.type";
import { IInterview } from "src/modules/interview/interface/interview.type";
import { IListParams, IResponse } from "src/utils/common.type";
import { RootState } from "..";
import { setDashboard } from "../reducers/dashboard";
import { setInterview } from "../reducers/interview";
import { updatePersonLists } from "../reducers/person";

const personService = new PersonService();

interface IPayload<T> {
  data: T;
  cb?: (response: IResponse<T>) => void;
}

export const getGroupList = createAsyncThunk(
  "groupList/get",
  async (payload?: IListParams) => {
    try {
      const resp = await getGroups(payload);
      return { data: resp.data?.data, metadata: resp.data?.meta };
    } catch (err) {
      const { error } = err as IResponse<IGroup>;

      throw error;
    }
  },
);

export const addGroup = createAsyncThunk(
  "group/add",
  async (
    payload: Omit<IPayload<Partial<IMutateGroup>>, "cb"> & {
      cb: (res: IResponseGroup) => void;
    },
  ) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.addGroup(data);
      if (cb) cb(resp.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IGroup>;

      throw error;
    }
  },
);

export const editGroup = createAsyncThunk(
  "group/edit",
  async (
    payload: Omit<IPayload<IMutateGroup>, "cb"> & {
      cb: (res: IResponseGroup) => void;
    },
  ) => {
    const { data, cb } = payload;
    try {
      if (!data.id) {
        return;
      }
      const resp = await personService.editGroup(data.id, data);
      if (cb) cb(resp.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IGroup>;

      throw error;
    }
  },
);

export const getGroup = createAsyncThunk(
  "group/get",
  async (payload: IPayload<number>) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.getGroup(data);
      if (cb) cb(resp.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IGroup>;

      throw error;
    }
  },
);

export const deleteGroup = createAsyncThunk(
  "group/delete",
  async (
    payload: IPayload<number>,
    { getState, dispatch }: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    const { data, cb } = payload;
    try {
      await personService.deleteGroup(data);
      if (cb) cb({ data: data });
      const { personList } = (getState() as RootState).person;
      const { meta: groupMeta, groupList } = (getState() as RootState).group;

      if ((groupList || []).length === 1 && groupMeta.current_page !== 1) {
        dispatch(getGroupList({ page: groupMeta.current_page - 1 || 1 }));
      }
      const formattedPersons = [...(personList || [])].map((person) => {
        const personGroups = person.groups || [];

        const groups = personGroups.filter((group) => group.id !== data);
        return { ...person, groups };
      });

      dispatch(updatePersonLists(formattedPersons));
      return data;
    } catch (err) {
      const { error } = err as IResponse<IGroup>;

      throw error;
    }
  },
);

export const getGroupCandidates = createAsyncThunk(
  "group/getGroupCandidates",
  async (payload: IPayload<number>) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.getGroupCandidates(data);
      if (cb) cb(resp.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IGroupCandidates>;

      throw error;
    }
  },
);

export const deleteGroupCandidate = createAsyncThunk(
  "group/deleteGroupCandidate",
  async (payload: IPayload<{ groupId: number; participantId: number }>) => {
    const { data, cb } = payload;
    try {
      await personService.deleteGroupCandidate({ ...data });
      if (cb) cb({ data: data });
      notify({ message: "Candidate successfully deleted." });
      return data;
    } catch (err) {
      const { error } = err as IResponse<IGroupCandidates>;

      throw error;
    }
  },
);

export const attachGroupWithInterview = createAsyncThunk(
  "group/attachGroupInterview",
  async (
    payload: Omit<
      IPayload<{
        interviewId: number;
        groups: number[];
      }>,
      "cb"
    > & { cb?: (res: IInterview) => void },
    { getState, dispatch }: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    const { data, cb } = payload;
    const { interviewId, groups } = data;
    const { dashboard } = (getState() as RootState).dashboard;
    const resp = await personService.attachGroupInterview(interviewId, {
      groups,
    });

    const currentInterviews = [...(dashboard || [])];

    const index = currentInterviews.findIndex(
      (interview) => interview.id === interviewId,
    );

    if (index !== -1) {
      currentInterviews[index] = {
        ...currentInterviews[index],
        groups_count:
          (currentInterviews[index].groups_count || 0) + groups.length,
      };
    }
    dispatch(setDashboard(currentInterviews));
    dispatch(setInterview(resp?.data?.data));
    if (cb) {
      cb(resp?.data?.data);
    }
    return resp.data;
  },
);

// only to search group. for dropdown selection
// do not need to store in redux
export const getGroups = async (payload?: IListParams) => {
  return personService.getGroupList(payload);
};
