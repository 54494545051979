import { createSlice } from "@reduxjs/toolkit";
import {
  IGroup,
  IGroupCandidates,
} from "src/modules/dashboard/People/interface/group.type";
import { IParticipant } from "src/modules/interview/interface/interview.type";
import { IPagination } from "src/utils/common.type";
import { initialMeta } from "src/utils/helper";
import {
  addGroup,
  attachGroupWithInterview,
  deleteGroup,
  deleteGroupCandidate,
  editGroup,
  getGroup,
  getGroupCandidates,
  getGroupList,
} from "../actions";

const defaultGroupCandidateDetails = {
  interviews: [],
  question_answered: 0,
  participant_details: {
    status: "sent",
    scores: null,
    notes: null,
  },
};

export interface IGroupSlice {
  groupList: IGroup[] | null;
  group: IGroup | null;
  message: string;
  loading: boolean;
  saving: boolean;
  error: string;
  meta: IPagination;
  groupListLoading: boolean;
  groupCandidates: IGroupCandidates[];
  groupCandidatesLoading: boolean;
  deleteGroupLoading: boolean;
  deleteGroupCandidateLoading: boolean;
}

const initialState: IGroupSlice = {
  groupList: [],
  group: null,
  groupCandidates: [],
  meta: initialMeta,
  loading: false,
  saving: false,
  error: "",
  message: "",
  groupListLoading: false,
  groupCandidatesLoading: false,
  deleteGroupLoading: false,
  deleteGroupCandidateLoading: false,
};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    resetGroup: (state) => {
      state.group = initialState.group;
    },
    // update groups
    updateGroupLists: (state, action) => {
      state.group = null;
      state.groupList = action.payload;
    },
  },
  extraReducers: (builder) => {
    //add-group
    builder.addCase(addGroup.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(addGroup.fulfilled, (state, action) => {
      state.saving = false;
      state.groupList = [...(state.groupList || []), action.payload];
    });
    builder.addCase(addGroup.rejected, (state, action) => {
      state.saving = false;
      state.group = null;
      state.error = action.error.message || "Something went wrong";
    });

    //edit-group
    builder.addCase(editGroup.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(editGroup.fulfilled, (state, action) => {
      state.saving = false;
      const id = action.payload.id;
      const currentGroups = state?.groupList || [];
      const index = (currentGroups || []).findIndex(
        (person) => person.id === id,
      );
      if (index !== -1) {
        currentGroups[index] = action.payload;
      }

      const updatedGroupCandidates = (action.payload.participants || []).map(
        (participant: IParticipant) => ({
          ...participant,
          ...defaultGroupCandidateDetails,
        }),
      );

      state.groupCandidates = updatedGroupCandidates;
      state.groupList = [...currentGroups];
      state.group = action.payload;
    });
    builder.addCase(editGroup.rejected, (state, action) => {
      state.saving = false;
      state.group = null;
      state.error = action.error.message || "Something went wrong";
    });

    //get-groupList
    builder.addCase(getGroupList.pending, (state) => {
      state.groupListLoading = true;
    });
    builder.addCase(getGroupList.fulfilled, (state, action) => {
      state.groupListLoading = false;
      state.groupList = action.payload.data;
      state.meta = action.payload.metadata;
    });
    builder.addCase(getGroupList.rejected, (state, action) => {
      state.groupListLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    //get-group
    builder.addCase(getGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.group = action.payload;
    });
    builder.addCase(getGroup.rejected, (state, action) => {
      state.loading = false;
      state.group = null;
      state.error = action.error.message || "Something went wrong";
    });

    //delete-group
    builder.addCase(deleteGroup.pending, (state) => {
      state.deleteGroupLoading = true;
      state.saving = true;
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      state.deleteGroupLoading = false;
      state.saving = false;
      state.meta = {
        ...state.meta,
        total: (state.meta?.total || 1) - 1,
        to: (state.meta?.to || 1) - 1,
      };
      state.groupList = (state.groupList || []).filter(
        (group) => group.id !== action.payload,
      );
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      state.deleteGroupLoading = false;
      state.saving = false;
      state.group = null;
      state.error = action.error.message || "Something went wrong";
    });
    //get-group-candidate
    builder.addCase(getGroupCandidates.pending, (state) => {
      state.groupCandidatesLoading = true;
    });
    builder.addCase(getGroupCandidates.fulfilled, (state, action) => {
      state.groupCandidatesLoading = false;
      state.groupCandidates = action.payload;
    });
    builder.addCase(getGroupCandidates.rejected, (state, action) => {
      state.groupCandidatesLoading = false;
      state.groupCandidates = [];
      state.error = action.error.message || "Something went wrong";
    });
    //delete-group-candidate
    builder.addCase(deleteGroupCandidate.pending, (state) => {
      state.deleteGroupCandidateLoading = true;
      state.saving = true;
    });
    builder.addCase(deleteGroupCandidate.fulfilled, (state, action) => {
      state.deleteGroupCandidateLoading = false;
      state.saving = false;
      state.groupCandidates = (state.groupCandidates || []).filter(
        (group) => group.id !== action.payload.participantId,
      );
    });
    builder.addCase(deleteGroupCandidate.rejected, (state, action) => {
      state.deleteGroupCandidateLoading = false;
      state.saving = false;
      state.groupCandidates = [];
      state.error = action.error.message || "Something went wrong";
    });

    //attach-group-interview
    builder.addCase(attachGroupWithInterview.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(attachGroupWithInterview.fulfilled, (state) => {
      state.saving = false;
    });
    builder.addCase(attachGroupWithInterview.rejected, (state) => {
      state.saving = false;
    });
  },
});
export const { resetGroup, updateGroupLists } = groupSlice.actions;
export default groupSlice.reducer;
