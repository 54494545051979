import { createSlice } from "@reduxjs/toolkit";
import { IPerson } from "src/modules/dashboard/People/interface/person.type";
import { IPagination } from "src/utils/common.type";
import { initialMeta } from "src/utils/helper";
import {
  addPerson,
  deletePerson,
  editPerson,
  getInterviewParticipant,
  getPerson,
  getPersonsList,
} from "../actions";

export interface IPersonSlice {
  person: IPerson | null;
  personList: IPerson[] | null;
  message: string;
  loading: boolean;
  saving: boolean;
  personListLoading: boolean;
  error: string;
  meta: IPagination;
  deletePersonLoading: boolean;
  participantLoading: boolean;
}

const initialState: IPersonSlice = {
  person: null,
  personList: [],
  loading: false,
  participantLoading: false,
  error: "",
  message: "",
  meta: initialMeta,
  personListLoading: false,
  saving: false,
  deletePersonLoading: false,
};

export const personSlice = createSlice({
  name: "person",
  initialState,
  reducers: {
    resetPerson: (state) => {
      state.person = initialState.person;
    },
    updatePersonLists: (state, action) => {
      state.personList = action.payload;
    },
  },
  extraReducers: (builder) => {
    //add-person
    builder.addCase(addPerson.pending, (state) => {
      state.saving = true;
      state.person = null;
    });
    builder.addCase(addPerson.fulfilled, (state, action) => {
      state.saving = false;
      state.person = action.payload;
      state.personList = [...(state.personList || []), action.payload];
    });
    builder.addCase(addPerson.rejected, (state, action) => {
      state.saving = false;
      state.person = null;
      state.error = action.error.message || "Something went wrong";
    });

    //edit-person
    builder.addCase(editPerson.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(editPerson.fulfilled, (state, action) => {
      state.saving = false;
      const id = action.payload.id;
      const currentCandidates = state?.personList || [];
      const existingCandidateIndex = (currentCandidates || []).findIndex(
        (person) => person.id === id,
      );
      if (existingCandidateIndex !== -1) {
        currentCandidates[existingCandidateIndex] = action.payload;
      }
      state.personList = [...currentCandidates];
      state.person = action.payload;
    });
    builder.addCase(editPerson.rejected, (state, action) => {
      state.saving = false;
      state.person = null;
      state.error = action.error.message || "Something went wrong";
    });

    //get-personList
    builder.addCase(getPersonsList.pending, (state) => {
      state.personListLoading = true;
    });
    builder.addCase(getPersonsList.fulfilled, (state, action) => {
      state.personListLoading = false;
      state.personList = action.payload.data;
      state.meta = action.payload.metadata;
    });
    builder.addCase(getPersonsList.rejected, (state, action) => {
      state.personListLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    //get-person
    builder.addCase(getPerson.pending, (state) => {
      state.loading = true;
      state.person = null;
    });
    builder.addCase(getPerson.fulfilled, (state, action) => {
      state.loading = false;
      state.person = action.payload;
    });
    builder.addCase(getPerson.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    //get-interview-participant ex: charts, questions
    builder.addCase(getInterviewParticipant.pending, (state) => {
      state.participantLoading = true;
    });
    builder.addCase(getInterviewParticipant.fulfilled, (state, action) => {
      state.participantLoading = false;
      state.person = { ...state.person, ...action.payload };
    });
    builder.addCase(getInterviewParticipant.rejected, (state, action) => {
      state.participantLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    //delete-person
    builder.addCase(deletePerson.pending, (state) => {
      state.saving = true;
      state.deletePersonLoading = true;
    });
    builder.addCase(deletePerson.fulfilled, (state, action) => {
      state.saving = false;
      state.deletePersonLoading = false;
      state.meta = {
        ...state.meta,
        total: (state.meta?.total || 1) - 1,
        to: (state.meta?.to || 1) - 1,
      };
      state.personList = (state.personList || []).filter(
        (person) => person.id !== action.payload,
      );
    });
    builder.addCase(deletePerson.rejected, (state, action) => {
      state.saving = false;
      state.deletePersonLoading = false;

      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const { resetPerson, updatePersonLists } = personSlice.actions;
export default personSlice.reducer;
