import cx from "classnames";
import React from "react";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import classes from "./button.module.scss";
import { Props } from "./types";

const Button: React.FC<Props> = (Props) => {
  const {
    variant = "fill",
    rightIcon,
    leftIcon,
    loading = false,
    disabled = false,
    buttonColor = "primary",
    className = "",
    children,
    fullWidth = false,
    ...rest
  } = Props;

  const hasIcons = !!rightIcon || !!leftIcon || loading;

  const renderLeftIcon = () => {
    if (!leftIcon) return;
    return <span className={cx(classes.leftIconStyling)}>{leftIcon}</span>;
  };

  const renderRightIcon = () => {
    if (!rightIcon) return;
    return <span className={cx(classes.rightIconStyling)}>{rightIcon}</span>;
  };

  const renderButton = () => {
    if (!children) return;
    return (
      <span
        className={cx(classes.buttonWrapper, {
          "btn-inner--text": hasIcons,
          [classes.fullWidth]: fullWidth,
        })}
      >
        {children}
      </span>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading || disabled) {
      event.preventDefault();
      return;
    }
  };
  const newClassName = cx(
    classes.buttonStyling,
    classes[`${buttonColor}-${variant}`],
    {
      [classes.fullWidth]: fullWidth,
      [className]: className,
      "btn-icon": hasIcons,
      [classes.disabled]: disabled || loading,
    },
  );

  const renderChildren = () => {
    if (loading) {
      return <ButtonLoader />;
    }
    return (
      <>
        {renderLeftIcon()}
        {renderButton()}
        {renderRightIcon()}
      </>
    );
  };

  return (
    <button
      className={newClassName}
      onClick={handleClick}
      disabled={disabled || loading}
      {...rest}
    >
      {renderChildren()}
    </button>
  );
};

export default Button;
