import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { isLoggedIn } from "src/utils/helper";
import classes from "./publicLayout.module.scss";

interface Props {
  children: React.ReactNode;
}

const PublicLayout: React.FC<Props> = ({ children }) => {
  const isAuthenticated = isLoggedIn();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/interview");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (isAuthenticated) {
    return null;
  }
  return <div className={classes.container}>{children}</div>;
};

export default PublicLayout;
