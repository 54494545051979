import { createAsyncThunk } from "@reduxjs/toolkit";
import { IResponse } from "src/utils/common.type";

interface IPayload<T> {
  data: T;
  cb?: (response: IResponse<T>) => void;
}
export const saveAudio = createAsyncThunk(
  "media/saveAudio",
  async (payload: IPayload<Blob | null>) => {
    return payload;
  },
);
