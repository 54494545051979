import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "src/api/UserService";
import { notify } from "src/components/Toastify/Toastify";
import {
  IChangePassword,
  ICompany,
  ISettings,
  IUser,
} from "src/modules/users/interface/user.type";

import { IResponse } from "src/utils/common.type";

interface IPayload<T> {
  data: T;
  cb?: (response: IResponse<T>) => void;
}

const userService = new UserService();

export const getUser = createAsyncThunk("user/getUser", async () => {
  try {
    const resp = await userService.getUser();

    return resp.data?.data;
  } catch (err) {
    const { error } = err as IResponse<IUser>;

    throw error;
  }
});

export const createCompany = createAsyncThunk(
  "company/create",
  async (payload: IPayload<Pick<ICompany, "name">>) => {
    const { data, cb } = payload;
    try {
      const resp = await userService.createCompany(data);

      if (cb) {
        cb(resp);
      }
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<ICompany>;
      throw error;
    }
  },
);

export const upgradeNow = createAsyncThunk(
  "user/upgrade",
  async (payload: IPayload<string>) => {
    const { cb } = payload;
    try {
      const resp = await userService.upgradeNow();
      if (resp.status) {
        if (cb) {
          cb({ status: resp.status });
        }
      }
      return resp;
    } catch (err) {
      const { error } = err as IResponse<ICompany>;
      throw error;
    }
  },
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ data, cb }: IPayload<IChangePassword>) => {
    try {
      const resp = await userService.changePassword(data);
      notify({ message: "Your password was successfully updated" });
      if (cb) cb(resp.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IUser>;

      throw error;
    }
  },
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ data, cb }: { data: FormData; cb?: (response: IUser) => void }) => {
    try {
      const resp = await userService.updateUser(data);
      notify({ message: "Your profile was successfully updated" });
      if (cb) cb(resp.data?.data);
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IUser>;

      throw error;
    }
  },
);
export const getSettings = createAsyncThunk("user/getSettings", async () => {
  try {
    const resp = await userService.getSettings();

    return resp.data?.settings;
  } catch (err) {
    const { error } = err as IResponse<ISettings>;

    throw error;
  }
});

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ email, cb }: { email: string; cb?: () => void }) => {
    try {
      await userService.deleteUser({ email });
      notify({ message: "Your profile was successfully deleted" });
      if (cb) cb();
    } catch (err) {
      const { error } = err as IResponse<IUser>;

      throw error;
    }
  },
);
